/**
 * @flow
 */

import SoutherfieldsOgImage from '../data/southerfields-og-image.png';
import SoutherfieldsImageryOgImage from '../data/southerfields-imagery-og-image.png';
import SoutherfieldsMusicOgImage from '../data/southerfields-music-og-image.png';
import SoutherfieldsContactOgImage from '../data/southerfields-contact-og-image.png';

import type {OGImage} from 'src/components/OpenGraphTags';

export const NOOP = (...args: $ReadOnlyArray<any>) => {};
export const IS_BROWSER = typeof window !== 'undefined';

// from https://developers.facebook.com/apps/
export const FB_APP_ID = '596070395175650';
export const SITE_BASE_URL = 'https://southerfields.com';
export const EMAIL = 'contact@southerfields.com';
export const EMAIL_URL = `mailto:${EMAIL}`;
export const TWITTER_PROFILE_URL = 'https://twitter.com/southerfields';
export const INSTAGRAM_PROFILE_URL = 'https://www.instagram.com/southerfields/';
export const FACEBOOK_PROFILE_URL = 'https://www.facebook.com/southerfields';
export const SPOTIFY_PROFILE_URL =
  'https://open.spotify.com/artist/23LJzeiyVq9867cirxS5WH?si=fbe9m4ulS7ej4y78SXA4BQ&dl_branch=1';
export const SPOTIFY_FEATURED_URL =
  'https://open.spotify.com/album/4nU844QTRk4tbhauwVCZKG?si=5ORx3Kj2QKKbLtTYiS9oDQ&dl_branch=1';
export const AMAZON_MUSIC_PROFILE_URL =
  'https://music.amazon.com/artists/B08YWXLRNP/southerfields';
export const AMAZON_MUSIC_FEATURED_URL =
  'https://music.amazon.com/albums/B08YZ5MZX2';
export const APPLE_MUSIC_PROFILE_URL =
  'https://music.apple.com/us/artist/southerfields/1558245184';
export const APPLE_MUSIC_FEATURED_URL =
  'https://music.apple.com/us/album/mont-demos-ep/1558467246';
export const SOUNDCLOUD_PROFILE_URL = 'https://soundcloud.com/southerfields';
export const SOUNDCLOUD_FEATURED_URL =
  'https://soundcloud.com/southerfields/sets/mont-demos';
export const YOUTUBE_PROFILE_URL =
  'https://www.youtube.com/channel/UCoZ3vnBgOkpbxV6CnEwH2wQ';
export const YOUTUBE_FEATURED_URL =
  'https://www.youtube.com/watch?v=ZgMEuXmtW5k&list=OLAK5uy_lcCaOpU7v0WpafeEbLkfhRiGBDmAiTuFE';
export const BANDCAMP_PROFILE_URL = 'https://southerfields.bandcamp.com/';
export const MONT_DEMOS_FFM_URL = 'https://ffm.to/mont-demos';
export const TIKTOK_PROFILE_URL = 'https://www.tiktok.com/@southerfields';
export const ETSY_SHOP_URL = 'https://www.etsy.com/shop/southerfields';

export const DEFAULT_OG_IMAGE: OGImage = {
  url: SITE_BASE_URL + SoutherfieldsOgImage,
  alt: 'Logo for artist and musician Southerfields, showing a simple line drawing of a bird head.',
};
export const IMAGERY_OG_IMAGE: OGImage = {
  url: SITE_BASE_URL + SoutherfieldsImageryOgImage,
  alt:
    'Logo for artist and musician Southerfields, showing a simple line drawing of a bird with a' +
    'paint brush.',
};
export const MUSIC_OG_IMAGE: OGImage = {
  url: SITE_BASE_URL + SoutherfieldsMusicOgImage,
  alt:
    'Logo for artist and musician Southerfields, showing a simple line drawing of a bird wearing' +
    'headphones.',
};
export const CONTACT_OG_IMAGE: OGImage = {
  url: SITE_BASE_URL + SoutherfieldsContactOgImage,
  alt:
    'Logo for artist and musician Southerfields, showing a simple line drawing of a bird making a' +
    'phone call.',
};

export const ELEMENT_IDS = {
  IMAGERY_GALLERY_SEARCH: 'IMAGERY_GALLERY_SEARCH',
  IMAGERY_GALLERY_MEDIUM_GROUP: 'IMAGERY_GALLERY_MEDIUM_GROUP',
  IMAGERY_GALLERY_COLLECTION: 'IMAGERY_GALLERY_COLLECTION',
  IMAGERY_GALLERY_ADDITIONAL_FILTERS: 'IMAGERY_GALLERY_ADDITIONAL_FILTERS',
  THEME_CONTROLLER_VARIABLE: 'THEME_CONTROLLER_VARIABLE',
};

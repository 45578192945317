/**
 * @flow
 */

import facepaint from 'facepaint';

import type {ObjectStyle} from 'src/styles/themes';

export type WithBreakpoints = (ObjectStyle) => ObjectStyle;

export function getWithBreakpoints(
  breakpoints: $ReadOnlyArray<number>,
): WithBreakpoints {
  return facepaint(
    breakpoints.map((breakpoint) => `@media(min-width: ${breakpoint}px)`),
  );
}

/**
 * @flow
 */

import * as React from 'react';
import InternalLink from 'src/components/navigation/InternalLink';
import ExternalLink from 'src/components/navigation/ExternalLink';
import CenterWithMaxWidth from 'src/components/CenterWithMaxWidth';
import {useTheme} from '@emotion/react';
import {getWithBreakpoints} from 'src/styles/breakpoints';
import SoutherfieldsIcon from 'src/components/icons/SoutherfieldsIcon';
import {linkStyle, visuallyHiddenStyle} from 'src/styles/common';
import {ETSY_SHOP_URL} from 'src/constants';

// 355 is just a nice looking width at which to have the brand icon disappear
// 530 is just a nice looking width at which to have the brand name disappear
const withHeaderBreakpoints = getWithBreakpoints([355, 530]);

const BRAND_LOGO_SIZE = 45;
const BRAND_LOGO_MARGIN = 8;
const linkBaseStyle = (theme) => ({
  ...linkStyle(theme),
  height: BRAND_LOGO_SIZE + 2 * BRAND_LOGO_MARGIN,
  padding: '0 12px',
  display: 'flex',
  alignItems: 'center',
  // needed for the linkActive:after to absolute position against
  position: 'relative',
});
const styles = {
  root: (theme) => ({
    backgroundColor: theme.unshadeColor,
  }),
  innerRoot: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexWrap: 'wrap',
  }),
  leftGroup: (theme) => ({
    ...linkStyle(theme),
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
    color: theme.fontColor,
  }),
  brandIcon: withHeaderBreakpoints({
    margin: BRAND_LOGO_MARGIN,
    display: ['none', 'flex', 'flex'],
    height: BRAND_LOGO_SIZE,
    width: BRAND_LOGO_SIZE,
  }),
  brand: withHeaderBreakpoints({
    margin: 0,
    fontSize: '3rem',
    fontWeight: 'normal',
    display: ['none', 'none', 'block'],
  }),
  linksList: {
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'center',
  },
  linkLi: {
    height: '100%',
  },
  link: (theme) => ({
    ...linkBaseStyle(theme),
  }),
  linkActive: (theme) => ({
    ...linkBaseStyle(theme),
    ':after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 4,
      background: theme.linkColor,
    },
  }),
};

const LINK_INFOS = [
  {
    route: '/imagery/',
    label: 'Imagery',
    isExternal: false,
    preserveQueryParams: true,
  },
  {
    route: '/music/',
    label: 'Music',
    isExternal: false,
    preserveQueryParams: false,
  },
  {
    route: ETSY_SHOP_URL,
    label: 'Shop',
    isExternal: true,
    preserveQueryParams: false,
  },
  {
    route: '/contact/',
    label: 'Contact',
    isExternal: false,
    preserveQueryParams: false,
  },
];

export default function PageHeader(): React.Node {
  const theme = useTheme();
  return (
    <CenterWithMaxWidth
      maxWidth={theme.maxContentWidth}
      Root={(props) => <header {...props} />}
      InnerRoot={(props) => <nav {...props} />}
      rootStyle={styles.root}
      innerRootStyle={styles.innerRoot}
    >
      <InternalLink css={styles.leftGroup} to='/'>
        <span css={styles.brandIcon}>
          <SoutherfieldsIcon />
        </span>
        {/* accessibility checker cant figure out that h1 has label, so added a hidden span */}
        <span css={visuallyHiddenStyle}>Southerfields</span>
        <h1 css={styles.brand}>Southerfields</h1>
      </InternalLink>
      <ul css={styles.linksList}>
        {LINK_INFOS.map(({label, route, isExternal, preserveQueryParams}) => (
          <li key={label} css={styles.linkLi}>
            {isExternal ? (
              <ExternalLink css={styles.link} href={route}>
                {label}
              </ExternalLink>
            ) : (
              <InternalLink
                css={styles.link}
                to={route}
                preserveQueryParams={preserveQueryParams}
                activeCss={styles.linkActive}
                partiallyActive={true}
              >
                {label}
              </InternalLink>
            )}
          </li>
        ))}
      </ul>
    </CenterWithMaxWidth>
  );
}

/**
 * @flow
 */

import * as React from 'react';
import IconLink, {
  twitterIconLinkInfo,
  facebookIconLinkInfo,
  instagramIconLinkInfo,
  tiktokIconLinkInfo,
  ICON_LINK_PADDING,
} from 'src/components/icons/IconLink';
import CenterWithMaxWidth from 'src/components/CenterWithMaxWidth';
import {useTheme} from '@emotion/react';
import {getWithBreakpoints} from 'src/styles/breakpoints';

// 530 is just a nice looking width at which to have the footer break to two rows
const withFooterBreakpoint = getWithBreakpoints([530]);

const styles = {
  root: (theme) => ({
    borderTop: `1px solid ${theme.shadeColor}`,
  }),
  innerRoot: (theme) =>
    withFooterBreakpoint({
      marginLeft: [0, ICON_LINK_PADDING],
      display: 'flex',
      flexDirection: ['column-reverse', 'row'],
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: [12, 0],
      color: theme.shadeColor,
    }),
  copyright: (theme) => ({
    fontSize: `${theme.fontSizeSmall}rem`,
  }),
};

const FOOTER_ICON_INFOS = [
  instagramIconLinkInfo,
  facebookIconLinkInfo,
  tiktokIconLinkInfo,
  twitterIconLinkInfo,
];

export default function PageFooter(): React.Node {
  const theme = useTheme();
  return (
    <CenterWithMaxWidth
      maxWidth={theme.maxContentWidth}
      Root={(props) => <footer {...props} />}
      rootStyle={styles.root}
      innerRootStyle={styles.innerRoot}
    >
      <div css={styles.copyright}>
        © 2021 Southerfields. All Rights Reserved.
      </div>
      <div>
        {FOOTER_ICON_INFOS.map((info) => (
          <IconLink
            key={info.label}
            iconLinkInfo={info}
            color={theme.shadeColor}
            hoverColor={theme.linkHoverColor}
          />
        ))}
      </div>
    </CenterWithMaxWidth>
  );
}

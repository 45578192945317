/**
 * @flow
 */

import {IS_BROWSER} from 'src/constants';

type Pixel = number;
type Rem = number;
export type ColorString = string;
export type Theme = $ReadOnly<{|
  maxContentWidth: Pixel,
  maxMainContentWidth: Pixel,
  pagePadding: Rem,
  thumbnailPadding: Rem,
  fontSizeSmall: Rem,
  fontSizeMedium: Rem,
  fontSizeLarge: Rem,
  fontSizeHuge: Rem,
  fontSizeIcons: Rem,
  linkColor: ColorString,
  linkHoverColor: ColorString,
  wash: ColorString,
  fontColor: ColorString,
  shadeColor: ColorString,
  unshadeColor: ColorString,
|}>;
export type ObjectStyle = any;
export type ThemedStyle = (Theme) => ObjectStyle;
export type Style = ObjectStyle | ThemedStyle;
export type ThemeValueController<TValue> = {
  get: () => TValue,
  set: (TValue) => void,
};
export type ThemeControllerObj = $ReadOnly<
  $ObjMap<Theme, <V>(V) => ThemeValueController<V>>,
>;

let theme: Theme = {
  maxContentWidth: 1500,
  /**
   * IMPORTANT: If you change this, you probably also need to adjust the size of
   * the image fetched by the graphql queries for both the
   *   - ImagerySearch (the css breakpoint in addition to the graphql query)
   *   - ImageryDetail
   * in order to avoid fetching more/less image size than needed by the eventual
   * layout.
   */
  maxMainContentWidth: 800,
  pagePadding: 3.2,
  thumbnailPadding: 0.4,

  fontSizeSmall: 1.4,
  fontSizeMedium: 1.6,
  fontSizeLarge: 2.2,
  fontSizeHuge: 5,
  fontSizeIcons: 2.8,

  linkColor: '#016fa6', // '#0174ad', // '#008ee6', // '#7758e4',
  linkHoverColor: '#2bacfd', // '#967df2',
  wash: '#ebebeb', // '#f0e9e5', // '#e0e5f0',
  fontColor: '#000000', // '#0f141f',
  shadeColor: '#696969', // 'hsl(0deg 0% 0% / 36%)',
  unshadeColor: '#f5f5f5', // 'hsl(0deg 0% 100% / 36%)',
};

if (IS_BROWSER) {
  window.southerfieldsExportTheme = () => console.log(theme);
}

export function getTheme(): Theme {
  return theme;
}

export function createThemeControllerObj(
  forceUpdatePage: () => void,
): ThemeControllerObj {
  const result = {};
  for (const prop in theme) {
    result[prop] = {
      // intentionally referencing module scoped theme var to read / write it
      // eslint-disable-next-line no-loop-func
      get: () => theme[prop],
      // intentionally referencing module scoped theme var to read / write it
      // eslint-disable-next-line no-loop-func
      set: (value) => {
        theme = {...theme, [prop]: value};
        forceUpdatePage();
      },
    };
  }
  // not sure why i need to cast here... *shrug*
  return (result: any);
}

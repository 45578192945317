/**
 * @flow
 */

import * as React from 'react';

import type {Style} from 'src/styles/themes';

type RootProps = $ReadOnly<{
  css: any,
  children: React.Node,
}>;
type Props = $ReadOnly<{
  rootStyle?: Style,
  innerRootStyle?: Style,
  Root?: React.ComponentType<RootProps>,
  InnerRoot?: React.ComponentType<RootProps>,
  children: React.Node,
  maxWidth: number,
}>;

function RootDefault(props: RootProps): React.Node {
  return <div {...props} />;
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  innerRoot: {
    width: '100%',
  },
};

export default function CenterWithMaxWidth(props: Props): React.Node {
  const {
    maxWidth,
    children,
    Root = RootDefault,
    InnerRoot = RootDefault,
    rootStyle,
    innerRootStyle,
  } = props;
  const finalInnerRootStyle = {
    ...styles.innerRoot,
    maxWidth: maxWidth,
  };
  return (
    <Root css={[styles.root, rootStyle]}>
      <InnerRoot css={[finalInnerRootStyle, innerRootStyle]}>
        {children}
      </InnerRoot>
    </Root>
  );
}

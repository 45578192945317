/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{
  label: string,
  value: number,
  onChange: (number) => any,
  min: number,
  max: number,
  step: number,
  id: string,
}>;

export default function Slider(props: Props): React.Node {
  const {label, value, onChange, min, max, step, id} = props;
  return (
    <React.Fragment>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type='range'
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(event) => onChange(parseFloat(event.target.value))}
      />
    </React.Fragment>
  );
}

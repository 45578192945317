/**
 * @flow
 */

import type {Theme, Style} from 'src/styles/themes';

// See here for why this specific styling to hide visually but keep a11y:
// https://www.emgoto.com/react-search-bar/
export const visuallyHiddenStyle = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  width: 1,
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
};

export const linkStyle = (theme: Theme): Style => ({
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.linkColor,
  ':hover': {
    color: theme.linkHoverColor,
  },
});

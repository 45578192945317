/**
 * @flow
 */

import * as React from 'react';
import PageHeader from 'src/components/PageHeader';
import PageFooter from 'src/components/PageFooter';
import ThemeController from 'src/components/ThemeController';
import CenterWithMaxWidth from 'src/components/CenterWithMaxWidth';
import useForceUpdate from 'src/hooks/useForceUpdate';
import {ThemeProvider, Global} from '@emotion/react';
import {getTheme, createThemeControllerObj} from 'src/styles/themes';
import {Helmet} from 'react-helmet';
import {SITE_BASE_URL} from 'src/constants';
import OpenGraphTags from 'src/components/OpenGraphTags';

import type {Style} from 'src/styles/themes';
import type {OGImage} from 'src/components/OpenGraphTags';

/**
 * IMPORTANT: Intentionally separate out this import because it has the side
 * effect of styling the page.
 */
import 'normalize.css';

type Props = $ReadOnly<{
  title: string,
  description: string,
  canonicalPath: string,
  ogImage: OGImage,
  pageRootStyle?: ?Style,
  children: React.Node,
  verticallyCenterContent?: ?boolean,
}>;

const styles = {
  global: (theme) => ({
    '*': {
      padding: 0,
      margin: 0,
      boxSizing: 'border-box',
    },
    'html, body, #___gatsby, #gatsby-focus-wrapper': {
      height: '100%',
      minHeight: '100%',
      backgroundColor: theme.wash,
      fontFamily: 'system-ui, Helvetica, Arial, sans-serif',
      // Make it so that 1rem = 10px because 62.5% of default browser font size (16px) is 10px
      fontSize: '62.5%',
    },
  }),
  root: (theme) => ({
    backgroundColor: theme.wash,
    color: theme.fontColor,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    // set everything to 16px font size (relies on <html> fontSize being 62.5%)
    fontSize: `${theme.fontSizeMedium}rem`,
  }),
  pageRoot: (theme) => ({
    flexGrow: 1,
    padding: `${theme.pagePadding}rem`,
  }),
  pageInnerRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
};

export default function Page(props: Props): React.Node {
  const {
    title,
    description,
    canonicalPath,
    ogImage,
    pageRootStyle,
    children,
    verticallyCenterContent,
  } = props;
  const theme = getTheme();
  const forceUpdate = useForceUpdate();
  const themeControllerObj = createThemeControllerObj(forceUpdate);
  const pageInnerRootStyle = {
    ...styles.pageInnerRoot,
    justifyContent: verticallyCenterContent ?? false ? 'center' : 'flex-start',
  };
  const prefixedTitle = `Southerfields | ${title}`;
  const canonicalUrl = SITE_BASE_URL + canonicalPath;
  return (
    <ThemeProvider theme={theme}>
      <OpenGraphTags
        title={prefixedTitle}
        description={description}
        url={canonicalUrl}
        image={ogImage}
      />
      <Helmet>
        <html lang='en' />
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <title>{prefixedTitle}</title>
        <link rel='canonical' href={canonicalUrl} />
      </Helmet>
      <Global styles={styles.global} />
      <div css={styles.root}>
        <ThemeController themeControllerObj={themeControllerObj} />
        <PageHeader />
        <CenterWithMaxWidth
          maxWidth={theme.maxMainContentWidth}
          Root={(props) => <main {...props} />}
          rootStyle={pageRootStyle ?? styles.pageRoot}
          innerRootStyle={pageInnerRootStyle}
        >
          {children}
        </CenterWithMaxWidth>
        <PageFooter />
      </div>
    </ThemeProvider>
  );
}

/**
 * @flow
 */

import {useReducer} from 'react';

/**
 * See here for inspiration:
 * https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
 */
export default function useForceUpdate(): () => void {
  // TODO: better eslint config...
  // eslint-disable-next-line no-unused-vars
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  return forceUpdate;
}

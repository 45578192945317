/**
 * @flow
 */

import * as React from 'react';
import {Helmet} from 'react-helmet';
import {FB_APP_ID} from 'src/constants';

export type OGImage = {
  url: string,
  alt: string,
  // type: string,
  // width: number,
  // height: number,
};
type Props = $ReadOnly<{
  title: string,
  description: string,
  url: string,
  image: OGImage,
}>;

/**
 * Open Graph docs: https://ogp.me/
 */
export default function OpenGraphTags(props: Props): React.Node {
  const {title, description, url, image} = props;
  return (
    <Helmet>
      <html lang='en' prefix='og: https://ogp.me/ns#' />
      <meta property='fb:app_id' content={FB_APP_ID} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:site_name' content='Southerfields' />
      <meta property='og:image' content={image.url} />
      <meta property='og:image:secure_url' content={image.url} />
      <meta property='og:image:alt' content={image.alt} />
      {/* <meta property='og:image:type' content={image.type} />
      <meta property='og:image:width' content={image.width} />
      <meta property='og:image:height' content={image.height} /> */}
    </Helmet>
  );
}

/**
 * @flow
 */

import * as React from 'react';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

type Props = $ReadOnly<{
  children: React.Node,
}>;

export default function ExternalLink(props: Props): React.Node {
  const {children, ...passedProps} = props;
  // NOTE: see here about the noopener stuff
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
  return (
    <OutboundLink {...passedProps} target='_blank' rel='noopener noreferrer'>
      {children}
    </OutboundLink>
  );
}

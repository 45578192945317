/**
 * @flow
 */

import * as React from 'react';
import {ClassNames, useTheme} from '@emotion/react';
import {Link} from 'gatsby';

import {IS_BROWSER} from 'src/constants';

import type {Style} from 'src/styles/themes';

type Props = $ReadOnly<{
  to: string,
  preserveQueryParams?: ?boolean,
  activeCss?: Style,
  partiallyActive?: ?boolean,
}>;

export default function InternalLink(props: Props): React.Node {
  const {activeCss, to, preserveQueryParams = false, ...passedProps} = props;
  const theme = useTheme();
  let activeClassName = null;
  if (activeCss != null) {
    activeClassName =
      typeof activeCss === 'function' ? activeCss(theme) : activeCss;
  }
  let finalTo = to;
  if (IS_BROWSER && preserveQueryParams) {
    const params = new URLSearchParams(window.location.search).toString();
    if (params !== '') {
      finalTo += '?' + params;
    }
  }
  return (
    <ClassNames>
      {({css}) => {
        const activeClassNameCss = css(activeClassName);
        return (
          <Link
            {...passedProps}
            // Adapted from
            //   https://github.com/reach/router/issues/280#issuecomment-513154260
            //   https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-link/src/index.js#L178
            getProps={({href, location}) => {
              const hrefWithoutQuery = href.split('?')[0];
              const isPartiallyCurrent =
                location.pathname.startsWith(hrefWithoutQuery);
              return props.partiallyActive && isPartiallyCurrent
                ? {className: activeClassNameCss}
                : null;
            }}
            to={finalTo}
          />
        );
      }}
    </ClassNames>
  );
}

/**
 * @flow
 */

import * as React from 'react';
import Slider from 'src/components/input/Slider';
import ColorPicker from 'src/components/input/ColorPicker';
import {IS_BROWSER} from 'src/constants';

import type {
  ThemeControllerObj,
  ThemeValueController,
  Theme,
} from 'src/styles/themes';
import {ELEMENT_IDS} from 'src/constants';

type Props = {
  themeControllerObj: ThemeControllerObj,
};

const styles = {
  root: {
    backgroundColor: 'white',
    color: 'black',
    position: 'absolute',
    bottom: 12,
    right: 12,
    padding: 12,
    zIndex: 100,
    // intentionally set all children to static pixel font size so that font
    // size sliders dont move due to ui reflow while you are dragging them
    ' > *, h3, h4': {
      fontSize: 16,
    },
  },
  titleBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    padding: 3,
  },
};

type ThemeControllerSliderProps = $ReadOnly<{
  themeControllerObj: ThemeControllerObj,
  info: $ReadOnly<{
    id: $Keys<Theme>,
    min: number,
    max: number,
    step: number,
  }>,
}>;
function ThemeControllerSlider(props: ThemeControllerSliderProps): React.Node {
  const {
    themeControllerObj,
    info: {id, min, max, step},
  } = props;
  // need to cast this because flow doesnt know that the id im grabbing maps to a number val
  const themeValueController: ThemeValueController<number> =
    (themeControllerObj[id]: any);
  return (
    <div key={id}>
      <Slider
        id={ELEMENT_IDS.THEME_CONTROLLER_VARIABLE + id}
        label={id}
        min={min}
        max={max}
        step={step}
        value={themeValueController.get()}
        onChange={themeValueController.set}
      />
    </div>
  );
}

export default function ThemeController({
  themeControllerObj,
}: Props): React.Node {
  const [isShown, setIsShown] = React.useState(false);
  if (IS_BROWSER) {
    window.southerfieldsToggleThemeController = () => {
      setIsShown(!isShown);
    };
  }
  if (!isShown) {
    return null;
  }
  return (
    <div css={styles.root}>
      <div css={styles.titleBar}>
        <h3>Theme Controller</h3>
        <button
          css={styles.closeButton}
          onClick={window.southerfieldsToggleThemeController}
        >
          X
        </button>
      </div>
      <h4>Misc Sizes</h4>
      {[
        {id: 'maxContentWidth', min: 0, max: 3000, step: 1},
        {id: 'maxMainContentWidth', min: 0, max: 3000, step: 1},
        {id: 'pagePadding', min: 0, max: 5, step: 0.1},
        {id: 'thumbnailPadding', min: 0, max: 5, step: 0.1},
      ].map((info) => (
        <ThemeControllerSlider
          key={info.id}
          themeControllerObj={themeControllerObj}
          info={info}
        />
      ))}
      <h4>Font Sizes</h4>
      {[
        {id: 'fontSizeSmall', min: 0, max: 3, step: 0.1},
        {id: 'fontSizeMedium', min: 0, max: 3, step: 0.1},
        {id: 'fontSizeLarge', min: 0, max: 3, step: 0.1},
        {id: 'fontSizeHuge', min: 0, max: 7, step: 0.1},
        {id: 'fontSizeIcons', min: 0, max: 3, step: 0.1},
      ].map((info) => (
        <ThemeControllerSlider
          key={info.id}
          themeControllerObj={themeControllerObj}
          info={info}
        />
      ))}
      <h4>Colors</h4>
      {[
        'linkColor',
        'linkHoverColor',
        'wash',
        'fontColor',
        'shadeColor',
        'unshadeColor',
      ].map((id) => (
        <div key={id}>
          <ColorPicker
            id={ELEMENT_IDS.THEME_CONTROLLER_VARIABLE + id}
            label={id}
            value={themeControllerObj[id].get()}
            onChange={themeControllerObj[id].set}
          />
        </div>
      ))}
    </div>
  );
}

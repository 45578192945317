/**
 * @flow
 */

import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * IMPORTANT: You gotta import things like this (full path to specific icon). If
 * you dont then the bundler will just bundle the whole index.es.js of the full
 * font pack module, rather than just the icons you actually use.
 */
import {faSpotify} from '@fortawesome/free-brands-svg-icons/faSpotify';
import {faItunesNote} from '@fortawesome/free-brands-svg-icons/faItunesNote';
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faTiktok} from '@fortawesome/free-brands-svg-icons/faTiktok';
import {faEtsy} from '@fortawesome/free-brands-svg-icons/faEtsy';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons/faEnvelope';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';

export function TwitterIcon(): React.Node {
  return <FontAwesomeIcon icon={faTwitter} />;
}

export function InstagramIcon(): React.Node {
  return <FontAwesomeIcon icon={faInstagram} />;
}

export function FacebookIcon(): React.Node {
  return <FontAwesomeIcon icon={faFacebook} />;
}

export function TiktokIcon(): React.Node {
  return <FontAwesomeIcon icon={faTiktok} />;
}

export function SpotifyIcon(): React.Node {
  return <FontAwesomeIcon icon={faSpotify} />;
}

export function AppleMusicIcon(): React.Node {
  return <FontAwesomeIcon icon={faItunesNote} />;
}

export function YoutubeIcon(): React.Node {
  return <FontAwesomeIcon icon={faYoutube} />;
}

export function EtsyIcon(): React.Node {
  return <FontAwesomeIcon icon={faEtsy} />;
}

export function EnvelopeIcon(): React.Node {
  return <FontAwesomeIcon icon={faEnvelope} />;
}

export function ChevronRightIcon(): React.Node {
  return <FontAwesomeIcon icon={faChevronRight} />;
}

export function ArrowLeftIcon(): React.Node {
  return <FontAwesomeIcon icon={faArrowLeft} />;
}

export function ArrowRightIcon(): React.Node {
  return <FontAwesomeIcon icon={faArrowRight} />;
}

/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{}>;

export default function SoutherfieldsIcon(props: Props): React.Node {
  const commonProps = {
    stroke: 'currentColor',
    fill: 'currentColor',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    opacity: 1,
  };
  return (
    <svg height='100%' width='100%' viewBox='0 0 100 100'>
      <path
        {...commonProps}
        strokeWidth='5'
        d='M2.7793+50C2.7793+23.9207+23.9207+2.7793+50+2.7793C76.0793+2.7793+97.2207+23.9207+97.2207+50C97.2207+76.0793+76.0793+97.2207+50+97.2207C23.9207+97.2207+2.7793+76.0793+2.7793+50Z'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        strokeWidth='5'
        d='M3.66754+56.4663C3.66754+56.4663+21.5618+20.409+51.2226+21.1409C74.5676+21.717+78.6422+47.4587+78.8803+51.1116C79.1337+54.9983+69.5739+59.3399+66.3393+63.7093C63.1759+67.9828+57.1763+95.0363+56.752+96.774'
        strokeLinecap='butt'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        strokeWidth='3'
        d='M52.0392+43.1104C54.0821+43.7913+57.3734+44.983+59.1325+43.9616C60.8917+42.9402+58.0211+38.6543+55.898+36.6413C54.1355+34.9702+49.7126+34.0877+46.3078+36.3576C42.9031+38.6274+49.9964+42.4294+52.0392+43.1104Z'
      />
      <path
        {...commonProps}
        strokeWidth='3'
        d='M84.1293+58.7809C83.8782+60.89+73.7442+59.6007+69.8156+60.0448C67.9192+60.2592+66.1603+55.9113+64.3156+55.8088C62.4709+55.7063+59.0547+55.0914+55.8435+55.1256C54.2721+53.0417+54.3063+52.1194+55.092+50.6846C60.592+51.0945+70.1572+48.9082+76.2722+50.2405C77.9802+53.0759+84.3001+57.3461+84.1293+58.7809Z'
      />
    </svg>
  );
}

/**
 * @flow
 */

import * as React from 'react';

import type {ColorString} from 'src/styles/themes';

type Props = $ReadOnly<{
  label: string,
  value: ColorString,
  onChange: (ColorString) => any,
  id: string,
}>;

/**
 * NOTE: the native html color picker can only handle hex values, and no transparency :(
 */
export default function ColorPicker(props: Props): React.Node {
  const {label, value, onChange, id} = props;
  return (
    <React.Fragment>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type='color'
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </React.Fragment>
  );
}
